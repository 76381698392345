:root {
  --accent: #e8df6d;
  --primary: #236c82;
  --font: Gilroy;
}

body {
  color: var(--white);
  overflow: hidden;
}

.gem-canvas {
  transform: scale(0.5);
}

.egg-canvas {
  transform: scale(0.35);
}

@media (orientation: portrait) and (min-width: 375px) {
  .egg-canvas {
    transform: scale(0.45);
  }

  .gem-canvas {
    transform: scale(0.6);
  }
}

@media (orientation: portrait) and (min-width: 600px) {
  .egg-canvas {
    transform: scale(0.55);
  }

  .gem-canvas {
    transform: scale(0.7);
  }
}

@media (orientation: landscape) and (min-height: 680px) {
  .egg-canvas {
    transform: scale(0.55);
  }

  .gem-canvas {
    transform: scale(0.7);
  }
}

@media (orientation: landscape) and (min-height: 900px) {
  .egg-canvas {
    transform: scale(0.55);
  }

  .gem-canvas {
    transform: scale(0.7);
  }
}